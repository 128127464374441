import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CloseIcon from 'svg/CloseIcon';
import Button, { ButtonText } from 'components/Button';
import Modal from 'components/modals/Modal';
import classNames from 'styles/utils/classNames';

const talkSpecialistSchema = z.object({
  message: z.string().min(1, 'Message is required'),
});

type TalkSpecialistFormType = z.infer<typeof talkSpecialistSchema>;

const TalkSpecialistModal: React.FC<{ handleClose: () => void; isOpen: boolean }> = ({
  handleClose,
  isOpen,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TalkSpecialistFormType>({
    resolver: zodResolver(talkSpecialistSchema),
  });

  const onSubmit = (data: TalkSpecialistFormType) => {
    Sentry.captureMessage('Talk Specialist Form Submission', {
      extra: { data },
    });
    const subject = `Talk Specialist Inquiry`;
    const body = `Hey Bounce team,
    
${data.message}`;

    window.open(
      `mailto:team@bounce.game?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body,
      )}`,
      '_blank',
    );
  };

  return (
    <Modal handleClose={handleClose} isOpen={isOpen} classNameMaxWidth="max-w-[35rem]">
      <div className="flex items-center justify-between border-b px-ds-3xl py-5 max-sm:px-ds-lg">
        <h2 className="typography-product-heading-desktop max-sm:typography-product-heading-mobile">
          Ask a question
        </h2>
        <ButtonText onClick={handleClose}>
          <CloseIcon className="h-4 w-4" />
        </ButtonText>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col border-b px-ds-3xl pb-ds-3xl max-sm:px-ds-lg">
          <p className="max-sm:typography-product-button-label-medium typography-product-button-label-large mt-4 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            Average response time : 5 min
          </p>
          <label className="typography-product-subheading mt-8 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary max-sm:mt-6">
            Your message
          </label>
          <textarea
            {...register('message')}
            className={classNames(
              'text-color-text-lightmode-placeholder::placeholder dark:text-color-text-darkmode-placeholder::placeholder hide-number-stepper dark:text-color-text-darkmode-primary',
              'mt-4 h-11 min-h-20 w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0',
            )}
            placeholder="Enter your message"
          />
          {errors.message && <p className="mt-3 text-xs text-red-500">{errors.message.message}</p>}
        </div>
        <div className="flex justify-end px-ds-3xl py-5 max-sm:px-ds-lg">
          <Button type="submit" variant="primary" size="lg" className="sm:w-fit">
            Send
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TalkSpecialistModal;

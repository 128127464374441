import ProgressLine from './ProgressLine';
import WorkCard from './WorkCard';

const HowItWorksSection: React.FC<{ onGettingStarted: () => void }> = ({ onGettingStarted }) => {
  const howItWorksTitleJsx = (
    <div className="absolute left-1/2 top-0 z-[2] -translate-x-1/2 -translate-y-1/2 rounded-[1.5rem] border border-color-border-card-lightmode bg-color-brand-primary px-14 py-ds-2xl shadow-[-16px_10px_30px_0px_rgba(255,92,51,0.40)] dark:border-color-border-card-darkmode max-sm:px-12 max-sm:py-ds-xl">
      <h2 className="max-md:typography-informative-subheading typography-informative-display-secondary-mobile whitespace-nowrap text-white">
        How it{' '}
        <span className="max-md:typography-informative-subheading-highlight typography-informative-display-primary-mobile">
          works
        </span>
      </h2>
    </div>
  );

  return (
    <>
      <section className="mt-24 max-sm:mt-16">
        <div className="container relative mx-auto  max-sm:px-4">
          {howItWorksTitleJsx}
          <div className="relative flex gap-20  rounded-[3rem] border border-color-bg-input-lightmode-primary px-[4rem] pb-[25.6rem] pt-32 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.04)_inset] dark:border-color-bg-input-darkmode-primary max-lg:pb-[18rem] max-md:gap-10 max-sm:px-[1rem] max-sm:pb-[16rem] max-sm:pt-24">
            <ProgressLine />
            <div className="flex flex-1 flex-col gap-40 max-md:gap-20">
              <WorkCard
                num={1}
                title="Set your goals"
                desc="Decide what you want to improve—whether it’s mastering your serve, refining your net game, building consistency, or anything in between."
                images={['goals']}
                onGettingStarted={onGettingStarted}
              />
              <WorkCard
                sticky
                num={2}
                title="Choose how to learn"
                desc="Book on-court lessons, submit videos for feedback, join virtual coaching sessions, or explore premium content from the world’s top players and coaches."
                images={[
                  'on-court-lessons',
                  'video-analysis',
                  'virtual-coaching',
                  'exclusive-content',
                  'self-guided-practice',
                ]}
                onGettingStarted={onGettingStarted}
              />
              <WorkCard
                num={3}
                title="Track & share"
                desc="Monitor your progress with detailed feedback from coaches, practice logs, and performance stats. Share learnings and milestones with the world."
                images={['track-share']}
                onGettingStarted={onGettingStarted}
              />
            </div>
            <img
              src="/images/players/how-it-works/balls-bottom.png"
              alt="balls-bottom"
              className="absolute bottom-0 left-0 right-0 w-full rounded-b-[3rem] max-sm:hidden"
            />

            <img
              src="/images/players/how-it-works/balls-bottom-mob.png"
              alt="balls-bottom-mob"
              className="absolute bottom-0 left-0 right-0 w-full rounded-b-[3rem] sm:hidden"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorksSection;

import { useMemo } from 'react';
import Image from 'next/image';
import { Tooltip } from 'react-tooltip';
import { COACH_PAGE } from 'constants/pages';
import { SportsEnum } from 'types/generated/client';
import { pluralize } from 'utils/shared/pluralize';
import Location from 'svg/Location';
import Price from 'svg/Price';
import Link from 'components/Link';
import { sportStyles } from 'components/VideoAnalysis/utils';
import classNames from 'styles/utils/classNames';
import { CombinedCoachType } from '../props';
import { isCoachProfile } from '../utils';

const TopCoachCard: React.FC<{ coach: CombinedCoachType }> = ({ coach }) => {
  const getTextWithIcon = (
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
    text: string,
    tooltipId?: string,
  ) => (
    <div className="flex w-full min-w-0 gap-1">
      {<Icon className="h-4 w-4 shrink-0 text-color-text-lightmode-tertiary" />}
      {tooltipId && (
        <Tooltip id={tooltipId} place="bottom-start">
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Tooltip>
      )}
      <span
        dangerouslySetInnerHTML={{ __html: text }}
        data-tooltip-id={tooltipId}
        className="typography-product-text-card-desktop  truncate   text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary"
      />
    </div>
  );

  const sport = isCoachProfile(coach) && sportStyles[coach.defaultSport as SportsEnum];

  const { address, formattedDistance } = useMemo(() => {
    const formattedDistance = isCoachProfile(coach)
      ? pluralize({
          count: Math.round(coach.distance || 0),
          singular: 'mile',
          plural: 'miles',
        })
      : '';

    const address = isCoachProfile(coach)
      ? coach.defaultCoachCustomCourt?.fullAddress || coach.defaultCoachVenue?.addressString || ''
      : '';

    return {
      address,
      formattedDistance,
    };
  }, [coach]);

  const coachFullPriceText = isCoachProfile(coach)
    ? `${coach.priceUnitAmountCoachDefault ? `${'On-court ' + coach.onCourtPrice}` : ''} ${
        coach.priceUnitAmountCoachDefault! > 0 && coach.priceUnitAmountRemoteCoachDefault! > 0
          ? '+'
          : ''
      } ${coach.priceUnitAmountRemoteCoachDefault ? `${' Remote ' + coach.remotePrice}` : ''}`
    : '';

  return (
    <Link href={`${COACH_PAGE}/${coach.id}`}>
      <div className="cursor-pointer flex-col rounded-lg  outline-color-border-input-lightmode transition-all hover:outline hover:outline-offset-4 dark:outline-color-border-input-darkmode">
        <div className="relative h-[11rem] overflow-hidden rounded-lg border border-color-border-card-lightmode dark:border-color-border-card-darkmode">
          <Image
            src={coach.imageSrc}
            alt="coach-image"
            fill
            objectPosition="center"
            objectFit="cover"
          />
        </div>
        <div className="flex flex-col items-start py-2">
          <div className="flex w-full justify-between gap-2">
            <span className="typography-product-card-title-desktop">
              {isCoachProfile(coach) ? coach.fullName : ''}
            </span>
            {sport && (
              <div
                className={`flex h-5 items-center rounded-2xl ${sport.bgColor} px-2 text-xs ${sport.textColor} h-4 px-3 `}
              >
                {sport.label}
              </div>
            )}
          </div>
          <div className="mt-2 flex w-full min-w-0 flex-col gap-1">
            {getTextWithIcon(Price, coachFullPriceText)}
            {getTextWithIcon(Location, `${address} &bull; ${formattedDistance}`)}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TopCoachCard;

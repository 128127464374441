import { LESSONS_PAGE } from 'constants/pages';
import { useModal } from 'hooks/useModal';
import Button, { ButtonLink } from 'components/Button';
import AnimatedLeftAvatars from './AnimatedLeftAvatars';
import AnimatedMobileAvatars from './AnimatedMobileAvatars';
import AnimatedRightAvatars from './AnimatedRightAvatar';
import FindCoachModal from './FindCoachModal';

const ForSeriousPlayersSection = () => {
  const findCoachModal = useModal();

  return (
    <>
      <section className="container relative mx-auto mt-28 pb-[11.5rem] pt-12 max-lg:pb-20 max-sm:mt-16 max-sm:px-4 max-sm:pt-0">
        <AnimatedLeftAvatars />
        <AnimatedRightAvatars />

        <h3 className="typography-informative-display-secondary-mobile max-sm:typography-informative-subheading text-center">
          Bounce is for racket sports players <br className="max-sm:hidden" /> serious about{' '}
          <br className="max-sm:hidden" />
          <span className="typography-informative-display-primary-mobile max-sm:typography-informative-subheading-highlight">
            learning
          </span>{' '}
          and{' '}
          <span className="typography-informative-display-primary-mobile max-sm:typography-informative-subheading-highlight">
            improving
          </span>
          .
        </h3>
        <div className="mt-20 flex justify-center gap-ds-2xl max-sm:mt-ds-2xl max-sm:flex-col max-sm:gap-4">
          <ButtonLink href={LESSONS_PAGE} variant="brand" size="lg" className="sm:w-fit">
            Find a coach
          </ButtonLink>
          <Button
            variant="primary"
            size="lg"
            className="sm:w-fit"
            onClick={() => findCoachModal.openModal()}
          >
            My coach is already on Bounce
          </Button>
        </div>

        <AnimatedMobileAvatars />
      </section>
      <FindCoachModal isOpen={findCoachModal.isOpen} handleClose={findCoachModal.closeModal} />
    </>
  );
};

export default ForSeriousPlayersSection;

import Button from 'components/Button';
import classNames from 'styles/utils/classNames';

const WorkCard: React.FC<{
  num: number;
  title: string;
  desc: string;
  images: Array<string>;
  sticky?: boolean;
  onGettingStarted?: () => void;
}> = ({ num, title, desc, images, sticky, onGettingStarted }) => {
  return (
    <div className={classNames('grid grid-cols-2 gap-2 max-lg:grid-cols-1 max-lg:gap-10')}>
      <div className={classNames('flex h-fit gap-6', sticky && 'top-[6rem] z-[2] lg:sticky')}>
        <span className="font-family-poppins text-[80px] font-extrabold leading-[100%] max-sm:text-[48px]">
          {num}
        </span>
        <div className="flex max-w-[21.3rem] flex-col">
          <h3 className="typography-informative-heading-compact-desktop max-sm:typography-product-heading-mobile">
            {title}
          </h3>
          <p className="typography-informative-subheading-medium max-sm:typography-informative-caption mt-2">
            {desc}
          </p>
          <Button
            variant="brand"
            className="mt-4 w-fit"
            sizeDesktop="lg"
            sizeMobile="md"
            onClick={onGettingStarted}
          >
            Get Started
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-8">
        {images.map((img, i) => (
          <>
            <img
              key={i}
              src={`/images/players/how-it-works/${img}.png`}
              className={classNames(
                'h-auto max-w-[31.25rem] object-contain max-sm:hidden',
                sticky && 'sticky',
              )}
              style={{ top: `calc(6rem + ${i * 4}rem)` }}
              alt={`${img}.png`}
            />
            <img
              key={i}
              src={`/images/players/how-it-works/${img}-mob.png`}
              className={classNames(
                'h-auto w-auto max-w-[18rem] object-contain sm:hidden',
                sticky && 'sticky',
              )}
              style={{ top: `calc(6rem + ${i * 4}rem)` }}
              alt={`${img}.png`}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default WorkCard;

import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import debounce from 'lodash/debounce';
import { COACH_PAGE } from 'constants/pages';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import {
  GetUserProfileByNameSearchQuery,
  useGetUserProfileByNameSearchLazyQuery,
} from 'types/generated/client';
import CloseIcon from 'svg/CloseIcon';
import Button, { ButtonText } from 'components/Button';
import Link from 'components/Link';
import Modal from 'components/modals/Modal';
import classNames from 'styles/utils/classNames';

type UserProfileType = GetUserProfileByNameSearchQuery['userProfiles'][0];

const FindCoachModal: React.FC<{ isOpen: boolean; handleClose: () => void }> = ({
  isOpen,
  handleClose,
}) => {
  const [query, setQuery] = useState('');

  const [getUserProfileByNameSearch, { loading, data }] = useGetUserProfileByNameSearchLazyQuery();

  const [suggestions, setSuggestions] = useState([] as UserProfileType[]);

  const fetchCoaches = async (searchTerm: string) => {
    if (!searchTerm.trim()) return;

    try {
      const response = await getUserProfileByNameSearch({
        variables: { name: `%${searchTerm}%`, limit: 10 },
      });
      if (response.data?.userProfiles) {
        setSuggestions(response.data.userProfiles);
      }
    } catch (err) {
      captureException(err);
    }
  };

  const debouncedFetchCoaches = useCallback(debounce(fetchCoaches, 300), []);

  useEffect(() => {
    debouncedFetchCoaches(query);
  }, [query, debouncedFetchCoaches]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (e.target.value.trim() === '') {
      setSuggestions([]);
    }
  };

  const suggestionRow = (data: UserProfileType) => (
    <Link
      href={`${COACH_PAGE}/${data.username}`}
      key={data.username}
      className="flex cursor-pointer items-center gap-3 px-3 py-2"
    >
      <img
        src={data?.profileImageProviderUrl || EMPTY_COACH_AVATAR_SRC}
        alt={data.fullName || ''}
        className="h-14 w-14 rounded-full object-cover max-sm:h-10  max-sm:w-10"
      />
      <div className="flex flex-col gap-[0.1rem]">
        <h3 className="typography-product-subheading max-sm:typography-product-button-label-medium">
          {data.fullName}
        </h3>
        <p className="typography-informative-caption text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
          @{data.username}
        </p>
      </div>
    </Link>
  );

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      classNameMaxWidth="max-w-[35rem]"
      className="h-full overflow-visible"
    >
      <div className="flex items-center justify-between border-b px-ds-3xl py-5">
        <h2 className="typography-product-heading-desktop">Find my coach</h2>
        <ButtonText onClick={handleClose}>
          <CloseIcon className="h-4 w-4" />
        </ButtonText>
      </div>

      <div className="flex flex-col gap-3  px-ds-3xl pb-ds-3xl pt-ds-xl">
        <label className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          Type your coach’s name
        </label>

        <div className="relative">
          <input
            className="peer h-11 w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0"
            placeholder="Enter coach’s name"
            value={query}
            onChange={handleInputChange}
          />

          <div
            className={classNames(
              'absolute flex h-0 max-h-[18rem] w-full flex-col overflow-hidden  bg-color-bg-lightmode-primary  shadow transition-[height] duration-500 dark:bg-color-bg-darkmode-primary max-sm:bottom-12 max-sm:rounded-t-xl  sm:top-12  sm:rounded-b-2xl',
              suggestions.length > 0 &&
                'no-scrollbar !overflow-auto peer-focus-within:h-[20rem] peer-focus-within:max-sm:h-[30dvh]',
            )}
          >
            {suggestions.map((data) => suggestionRow(data))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FindCoachModal;

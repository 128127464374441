import { AnimatedAvatar } from '../styles';

const AnimatedMobileAvatars = () => {
  return (
    <div className="relative mt-12 h-[14rem] w-full lg:hidden">
      <AnimatedAvatar
        alt="avatar-1"
        src="/images/players/serious-players-avatars/left-1.png"
        delay="0s"
        className="absolute left-[15%] top-[20%] h-16 w-16"
      />
      <AnimatedAvatar
        alt="avatar-2"
        src="/images/players/serious-players-avatars/left-2.png"
        delay="1s"
        className="absolute left-[5%] top-[40%] h-14 w-14"
      />
      <AnimatedAvatar
        alt="avatar-3"
        src="/images/players/serious-players-avatars/left-3.png"
        delay="2s"
        className="absolute right-[5%] top-[50%] h-20 w-20"
      />
      <AnimatedAvatar
        alt="avatar-4"
        src="/images/players/serious-players-avatars/left-4.png"
        delay="3s"
        className="absolute bottom-0 left-[50%] h-24 w-24"
      />
      <AnimatedAvatar
        alt="avatar-8"
        src="/images/players/serious-players-avatars/right-4.png"
        delay="4s"
        className="absolute right-[40%] top-0 h-24 w-24"
      />
      <AnimatedAvatar
        alt="avatar-5"
        src="/images/players/serious-players-avatars/right-2.png"
        delay="5s"
        className="absolute left-[10%] top-[60%] h-16 w-16"
      />
      <AnimatedAvatar
        alt="avatar-6"
        src="/images/players/serious-players-avatars/right-1.png"
        delay="6s"
        className="absolute right-14 top-0  h-16 w-16"
      />
      <AnimatedAvatar
        alt="avatar-7"
        src="/images/players/serious-players-avatars/right-3.png"
        delay="7s"
        className="absolute right-0 top-10 h-20 w-20"
      />
    </div>
  );
};

export default AnimatedMobileAvatars;

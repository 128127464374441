import LoveBounceCard from './LoveBounceCard';
import { loveBounceData } from '../data';

const LoveBounceSection = () => {
  return (
    <section className="container mx-auto mt-28 max-sm:mt-16 max-sm:px-4">
      <h3 className="max-sm:typography-informative-subheading typography-informative-display-secondary-mobile text-center">
        Coaches{' '}
        <span className="typography-informative-display-primary-mobile max-sm:typography-informative-subheading-highlight">
          love
        </span>{' '}
        Bounce
      </h3>
      <div className="mt-8 grid grid-cols-4 gap-ds-xl max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
        {loveBounceData.map((item, index) => (
          <LoveBounceCard {...item} key={index} />
        ))}
      </div>
    </section>
  );
};

export default LoveBounceSection;

const LoveBounceCard: React.FC<{
  companyLogo: string;
  comment: string;
  owner: { name: string; designation: string; image: string };
}> = ({ comment, companyLogo, owner }) => {
  return (
    <div className="flex  flex-col rounded-[1.5rem] border border-color-border-card-lightmode p-ds-xl shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]  dark:border-color-border-card-darkmode">
      <img
        src={`/images/players/partners/${companyLogo}`}
        className="max-h-12 w-auto object-contain object-left"
        alt="company-logo"
      />
      <p className="max-sm:typography-product-caption mb-8 mt-4 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        {comment}
      </p>
      <div className="mt-auto flex items-center gap-2">
        <img
          src={`/images/players/coaches-avatar/${owner.image}`}
          alt="owner-avatar"
          className="h-8 w-8  rounded-full"
        />
        <div className="flex flex-col">
          <span className="typography-product-element-label">{owner.name}</span>
          <span className="typography-product-chips-filters  text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
            {owner.designation}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoveBounceCard;

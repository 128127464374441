import Link from 'next/link';
import { Button } from 'components/Button';
import classNames from 'styles/utils/classNames';
import { discoverImages } from './data';
import { DiscoverMoreStyled } from './styles';

const DiscoverMoreSection: React.FC = () => {
  return (
    <section className="mx-auto mt-32 pb-20 sm:container max-sm:mt-16 max-sm:px-4">
      <h2 className="max-sm:typography-informative-subheading typography-informative-display-secondary-mobile text-center">
        <mark className="max-sm:typography-informative-subheading-highlight  typography-informative-display-primary-mobile bg-transparent">
          Discover
        </mark>{' '}
        more
      </h2>
      <DiscoverMoreStyled className="mt-8 grid grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:mt-6 max-sm:grid-cols-1">
        {discoverImages.map(({ path, title, buttonProps }, index) => (
          <Link
            className={classNames('image-wrapper block min-h-[15rem]')}
            key={index}
            href={buttonProps.href}
          >
            <img
              loading="lazy"
              className="max-sm:grayscale-0"
              src={path}
              alt={`discover-img-${index}`}
            />
            <h3 className="typography-product-heading-compact-desktop  text-white">{title}</h3>
            <Button
              variant="brand"
              sizeDesktop="md"
              sizeMobile="xs"
              className="image-btn w-fit"
              iconRight={buttonProps.icon}
              onClick={() => {}}
            >
              {buttonProps.title}
            </Button>
          </Link>
        ))}
      </DiscoverMoreStyled>
    </section>
  );
};

export default DiscoverMoreSection;

import Button from 'components/Button';

const BounceNo1Section: React.FC<{ onGettingStarted: () => void }> = ({ onGettingStarted }) => {
  return (
    <section className="container mx-auto mt-28 flex flex-col items-center justify-center max-sm:mt-16 max-sm:px-4">
      <img src="/images/players/bounce-logo.gif" className="max-h-[8.75rem] w-auto" />
      <h2 className="max-sm:typography-informative-display-secondary-mobile typography-informative-display-mega-secondary-mobile mt-4 text-center">
        Bounce is the #1 app for <br />
        <span className="typography-informative-display-mega-primary-mobile max-sm:typography-informative-display-primary-mobile">
          racket sports
        </span>
      </h2>
      <Button
        variant="brand"
        size="lg"
        onClick={onGettingStarted}
        className="mt-10 w-fit shadow-[0px_4px_20px_0px_rgba(0,0,0,0.16)]"
      >
        Join Bounce for Free
      </Button>
    </section>
  );
};

export default BounceNo1Section;

const TrustedPartnersSection = () => {
  const partnerLogos = [
    'quatro',
    'ppr',
    'ptr',
    'iptpa',
    'picklr',
    'rfx',
    'pci',
    'racket-pro',
    'cardio-tennis',
  ];

  return (
    <section className="pt-20">
      <div className="container mx-auto max-sm:px-4">
        <h5 className="typography-informative-subheading-medium text-center">
          Trusted by industry leaders.
        </h5>
        <div className="mt-8 grid grid-cols-9 gap-8 max-xl:grid-cols-7 max-lg:grid-cols-5 max-md:grid-cols-3">
          {partnerLogos.map((partnerLogo, index) => (
            <img
              className="max-h-20 w-auto"
              src={`images/players/partners/${partnerLogo}.png`}
              alt={`partner-logo-${index}`}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TrustedPartnersSection;

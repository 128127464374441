import { useMemo } from 'react';
import { LESSONS_PAGE } from 'constants/pages';
import { GetCoachesByGeoQuery, GetRemoteCoachesByGeoQuery } from 'types/generated/client';
import { useGeoLocation } from 'hooks/useGeoLocation';
import { useModal } from 'hooks/useModal';
import ArrowRight from 'svg/ArrowRight';
import ChatBubble from 'svg/ChatBubble';
import Button, { ButtonLink, ButtonText } from 'components/Button';
import TalkSpecialistModal from './TalkSpecialistModal';
import TopCoachCard from './TopCoachCard';
import { formatCoaches } from '../utils';

const TopCoachesSection: React.FC<{
  onCourtLessonData: GetCoachesByGeoQuery | undefined;
  remoteLessobData: GetRemoteCoachesByGeoQuery | undefined;
}> = ({ onCourtLessonData, remoteLessobData }) => {
  const talkSpecialistModal = useModal();

  const { centerLatitude, centerLongitude } = useGeoLocation();

  const coaches = useMemo(() => {
    if (onCourtLessonData?.userProfiles && remoteLessobData?.userProfiles) {
      return formatCoaches({
        coaches: [...onCourtLessonData?.userProfiles, ...remoteLessobData?.userProfiles],
        centerLatitude: centerLatitude || 0,
        centerLongitude: centerLongitude || 0,
      }).splice(0, 4);
    }
    return [];
  }, [onCourtLessonData, remoteLessobData]);

  return (
    <>
      <section className="container mx-auto -translate-y-[8rem] px-[5rem]  max-md:px-[2.5rem] 2xl:-translate-y-[9.8rem]">
        <div className=" mx-auto  rounded-[2.5rem] border border-color-border-card-lightmode bg-color-bg-lightmode-primary px-14 py-ds-2xl shadow-[0px_-10px_30px_0px_rgba(0,0,0,0.08)] dark:border-color-border-card-darkmode dark:bg-color-bg-darkmode-primary max-sm:px-4">
          <h3 className="typography-informative-subheading max-sm:typography-informative-subheading-medium max-sm:text-center">
            Get started with <br className="sm:hidden" />
            <span className="typography-informative-subheading-highlight max-sm:typography-informative-subheading-medium-highlight">
              top coaches
            </span>{' '}
          </h3>
          <div className="mt-ds-xl grid grid-cols-4 gap-ds-xl max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            {coaches.map((coach) => (
              <TopCoachCard coach={coach} key={coach.id} />
            ))}
          </div>
          <div className="mt-9 flex flex-col items-center justify-center gap-9 max-sm:gap-6">
            <ButtonLink
              variant="brand"
              className="w-fit"
              sizeDesktop="lg"
              sizeMobile="md"
              href={LESSONS_PAGE}
              iconRight={<ArrowRight className="h-4 w-4" />}
            >
              Start Learning
            </ButtonLink>
            <ButtonText
              onClick={() => talkSpecialistModal.openModal()}
              className="flex items-center gap-2"
            >
              <ChatBubble className="h-4 w-4" />
              Talk to a lesson specialist
            </ButtonText>
          </div>
        </div>
      </section>
      <TalkSpecialistModal
        handleClose={talkSpecialistModal.closeModal}
        isOpen={talkSpecialistModal.isOpen}
      />
    </>
  );
};

export default TopCoachesSection;

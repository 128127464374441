import styled, { keyframes } from 'styled-components';

// Define keyframes for the animation
const moveUpDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveDownUp = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const HeroImagesWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: center;

  img {
    transition: transform 0.5s ease-in-out;
    border: 6px solid rgba(255, 255, 255, 0.4);
  }

  img:first-child {
    animation: ${moveUpDown} 5s ease-in-out infinite;
    max-height: 100%;
    box-shadow: 23.615px 57.326px 182px 0px rgba(52, 95, 179, 0.06),
      15.306px 37.156px 106.588px 0px rgba(52, 95, 179, 0.05),
      9.096px 22.081px 57.97px 0px rgba(52, 95, 179, 0.04),
      4.723px 11.465px 29.575px 0px rgba(52, 95, 179, 0.03),
      1.924px 4.671px 14.83px 0px rgba(52, 95, 179, 0.02),
      0.437px 1.062px 7.162px 0px rgba(52, 95, 179, 0.01);
    border-radius: 20px;
  }

  img:last-child {
    position: absolute;
    left: 2rem;
    top: 5rem;
    animation: ${moveDownUp} 5s ease-in-out infinite;
    border: 6px solid rgba(255, 255, 255, 0.4);
    width: auto;
    background-color: white;
    border-radius: 14px;
    box-shadow: 15.306px 37.156px 30px 0px rgba(52, 95, 179, 0.06),
      9.096px 22.081px 25px 0px rgba(52, 95, 179, 0.05),
      4.723px 11.465px 29.575px 0px rgba(52, 95, 179, 0.04),
      1.924px 4.671px 14.83px 0px rgba(52, 95, 179, 0.03),
      0.437px 1.062px 7.162px 0px rgba(52, 95, 179, 0.02);
  }
`;

export const fadeInOut = keyframes`
  0%, 25% {
    opacity: 1; /* Fully visible */
  }
  25%, 50% {
    opacity: 0; /* Fades out */
  }
  50%, 75% {
    opacity: 0; /* Hidden */
  }
  75%, 100% {
    opacity: 1; /* Fades in */
  }
`;

export const AnimatedAvatar = styled.img<{ delay: string }>`
  animation: ${fadeInOut} 4s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay};
  opacity: 0; /* Initial state hidden */
`;

import { ImageType } from './props';

export const loveBounceData = [
  {
    companyLogo: 'picklr-2.png',
    comment:
      'Bounce empowers Picklr to elevate our player and coach experience with seamless scheduling, player development tools, and innovative learning features.',
    owner: {
      name: 'Matt PaneGasser',
      designation: 'VP of Pickleball, Picklr',
      image: 'matt.png',
    },
  },
  {
    companyLogo: 'quatro-2.png',
    comment:
      'Bounce has transformed the way I manage my coaching business, from scheduling lessons to helping my players track their progress and improve faster.',
    owner: {
      name: 'Charlie Trevino',
      designation: 'Q4PB Partner & Head Professional',
      image: 'charlie.png',
    },
  },
  {
    companyLogo: 'ptr.png',
    comment:
      'Bounce makes running my coaching business effortless, from scheduling lessons to providing players with feedback and tracking their progress over time.',
    owner: {
      name: 'Brian Bass',
      designation: 'PTR Certified Coach',
      image: 'brian.png',
    },
  },
  {
    companyLogo: 'rfx.png',
    comment:
      'Bounce has been the perfect tool for me to organize my RFX classes. Players appreciate the ease of use and enjoy seeing who is going to be participating in group classes with them!',
    owner: {
      name: 'Parker Stinnett',
      designation: 'RFX Pickleball Fitness Instructor',
      image: 'parker.png',
    },
  },
];

export const animatedCarouselRowMob: Array<ImageType | JSX.Element> = [
  {
    src: '1',
    shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]',
  },
  { src: '2', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  <div className="px-2 py-6">
    <div className="relative">
      <img
        className="relative z-[2] h-[10rem]  w-auto"
        src="/images/players/carousel/m_3.png"
        alt="m_3"
      />
      <img
        src="/images/players/carousel/1_6_video.gif"
        className=" absolute inset-0 h-full w-full rounded-[1.5rem]"
      />
    </div>
  </div>,
  { src: '4', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  {
    src: '5',
    shadow:
      'shadow-[20px_10px_40px_0px_rgba(184,48,20,0.30)_inset,10px_-3px_30px_-8px_rgba(255,92,51,0.40)]',
  },
  { src: '6', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  { src: '7', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  <div className="px-2 py-6">
    <div className="relative max-w-[16.25rem]">
      <img
        className="relative z-[2] h-[10rem] w-auto object-contain object-center"
        src="/images/players/carousel/m_8.png"
        alt="m_8"
      />
      <img
        src="/images/players/carousel/2_1_video.gif"
        className="absolute inset-0 h-full w-full rounded-[1.5rem]"
      />
    </div>
  </div>,
  {
    src: '9',
    shadow:
      'shadow-[20px_10px_40px_0px_rgba(184,48,20,0.30)_inset,-3px_-3px_30px_-8px_rgba(255,92,51,0.40)]',
  },
  { src: '10', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
];

export const animatedCarouselRowOne: Array<ImageType | JSX.Element> = [
  {
    src: '1',
    shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]',
  },
  { src: '2', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  {
    src: '3',
    shadow:
      'shadow-[20px_10px_40px_0px_rgba(184,48,20,0.30)_inset,-3px_10px_30px_0px_rgba(255,92,51,0.40)]',
  },
  { src: '4', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  { src: '5', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  <div className="px-5 py-2">
    <div className="relative  max-w-[17.5rem]">
      <img
        className="absolute h-full w-full"
        src="/images/players/carousel/1_6_frame.png"
        alt="1_6_frame"
      />
      <img
        src="/images/players/carousel/1_6_video.gif"
        className="max-h-[11.25rem]  w-full rounded-[1.5rem]"
      />
    </div>
  </div>,
];

export const animatedCarouselRowTwo: Array<ImageType | JSX.Element> = [
  <div className="px-5 py-2">
    <div className="relative max-w-[17.5rem]  rounded-[1.5rem] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]">
      <img
        className="relative z-[2] max-h-[13.75rem] w-full max-w-[22rem] object-center"
        src="/images/players/carousel/2_1_frame.png"
        alt="2_1_frame"
      />
      <img
        src="/images/players/carousel/2_1_video.gif"
        className="absolute inset-0 z-[1]  h-full w-full max-w-[22rem] rounded-[1.5rem]"
      />
    </div>
  </div>,
  { src: '2', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  {
    src: '3',
    shadow:
      'shadow-[0px_20px_40px_0px_rgba(184,48,20,0.30)_inset,-3px_10px_30px_0px_rgba(255,92,51,0.30)]',
  },
  { src: '4', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  { src: '5', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
  { src: '6', shadow: 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.08)]' },
];

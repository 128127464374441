import { Marquee as DevMarquee } from '@devnomic/marquee';
import classNames from 'styles/utils/classNames';
import './marquee.css';
import { animatedCarouselRowMob, animatedCarouselRowOne, animatedCarouselRowTwo } from '../data';
import { isImageType } from '../utils';

const AnimatedCarousel = () => {
  return (
    <>
      <div className="flex flex-[0.5]  max-md:flex-[0.7] max-sm:!hidden">
        <DevMarquee
          className="max-h-[calc(100vh-theme(height.topnav))] gap-0"
          innerClassName="gap-0"
          direction="up"
          reverse={true}
        >
          {animatedCarouselRowOne.map((item, index) =>
            isImageType(item) ? (
              <div className="max-w-[17.5rem] px-5 py-2">
                <img
                  src={`images/players/carousel/1_${item.src}.png`}
                  key={`1_${index}`}
                  alt={`Player Carousel 1 - ${item.src}`}
                  className={classNames('w-full rounded-[1.5rem]', item.shadow)}
                />
              </div>
            ) : (
              item
            ),
          )}
        </DevMarquee>
        <DevMarquee
          direction="up"
          className="max-h-[calc(100vh-theme(height.topnav))] gap-0"
          innerClassName="gap-0"
        >
          {animatedCarouselRowTwo.map((item, index) =>
            isImageType(item) ? (
              <div className="max-w-[17.5rem] px-5 py-2">
                <img
                  src={`images/players/carousel/2_${item.src}.png`}
                  key={`2_${index}`}
                  alt={`Player Carousel 2 - ${item.src}`}
                  className={classNames(' rounded-[1.5rem]', item.shadow)}
                />
              </div>
            ) : (
              item
            ),
          )}
        </DevMarquee>
      </div>
      <DevMarquee className="gap-0 sm:!hidden" innerClassName="gap-0">
        {animatedCarouselRowMob.map((item, index) =>
          isImageType(item) ? (
            <div className="px-2 py-6">
              <img
                src={`images/players/carousel/m_${item.src}.png`}
                key={`1_${index}`}
                alt={`Player Carousel 1 Mob - ${item.src}`}
                className={classNames('h-[10rem] w-auto rounded-[1.5rem]', item.shadow)}
              />
            </div>
          ) : (
            item
          ),
        )}
      </DevMarquee>
    </>
  );
};

export default AnimatedCarousel;

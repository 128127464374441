const BounceOfficialPartners = () => {
  const getLogoWrapper = (src: string) => (
    <div className="flex items-center justify-center rounded-3xl bg-color-bg-lightmode-primary py-6 shadow-[0px_4px_40px_0px_rgba(0,0,0,0.20)]">
      <img className="max-w-[8.1rem]" src={`images/players/partners/${src}.png`} alt="logo" />
    </div>
  );

  return (
    <section className="container mx-auto px-[5rem] max-md:px-[2.5rem]  max-sm:-mt-8 max-sm:px-4">
      <div className="relative overflow-hidden rounded-[2.5rem]  border border-brand-fire-200  p-16 shadow-[-16px_10px_30px_0px_rgba(255,92,51,0.40)] max-sm:px-12 max-sm:py-8">
        <img
          src="/images/players/official-partners-bg-desktop.png"
          alt="official-partners"
          className="absolute inset-0 h-full w-full  object-cover max-sm:hidden"
        />

        <img
          src="/images/players/official-partners-bg-mob.png"
          alt="official-partners"
          className="absolute inset-0 h-full w-full object-cover sm:hidden"
        />

        <h3 className="max-sm:typography-informative-heading-mobile  typography-informative-heading-compact-desktop relative text-center text-color-text-lightmode-invert dark:text-color-text-darkmode-invert">
          Bounce is the official coaching software of:
        </h3>
        <div className="relative mt-[2.5rem] grid grid-cols-3 gap-ds-xl max-md:grid-cols-2 max-sm:grid-cols-1">
          {getLogoWrapper('ppr')}
          {getLogoWrapper('ptr')}
          {getLogoWrapper('picklr-2')}
        </div>
      </div>
    </section>
  );
};

export default BounceOfficialPartners;

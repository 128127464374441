import React from 'react';
import { COACHES_LANDING_PAGE } from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import CourtFlat from 'svg/CourtFlat';
import VideoCamera from 'svg/VideoCamera';
import Button from 'components/Button';
import Link from 'components/Link';
import AnimatedCarousel from './AnimatedCarousel';

const HeroSection: React.FC<{ onGettingStarted: () => void }> = ({ onGettingStarted }) => {
  const learnMoreJsx = (
    <>
      <p className="max-sm:typography-informative-body-superhighlight typography-informative-subheading-medium-highlight">
        Are you a coach?
      </p>
      <Link
        href={COACHES_LANDING_PAGE}
        className="max-sm:typography-informative-body-superhighlight typography-informative-subheading-medium-highlight  flex items-center gap-1 text-color-text-brand"
      >
        <span className="shrink-0">Learn More</span>
        <ArrowRight className="ml-1 h-5 w-5 shrink-0" />
      </Link>
    </>
  );

  const getIconWithText = (text: string, Icon: React.ElementType) => (
    <div className="flex items-center gap-3">
      <div className="flex  items-center justify-center rounded-full bg-color-brand-primary p-2 shadow-[0px_4px_20px_0px_rgba(0,0,0,0.16)] ">
        <Icon className="h-4 w-4 text-white" />
      </div>
      <span className="typography-informative-subheading-highlight max-sm:typography-informative-subheading-medium-highlight">
        {text}
      </span>
    </div>
  );

  return (
    <>
      <section className="mx-auto w-full sm:container max-sm:pt-12">
        <div className="flex items-center gap-4 max-sm:flex-col max-sm:gap-4">
          <div className="flex items-center px-4 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary  sm:flex-[0.6] ">
            <div className="flex max-w-[30rem] flex-col ">
              <h1 className="max-sm:typography-informative-display-secondary-mobile typography-informative-display-mega-secondary-mobile max-sm:text-pretty max-sm:text-center">
                Turn every lesson into{' '}
                <mark className="max-sm:typography-informative-display-primary-mobile typography-informative-display-mega-primary-mobile bg-transparent">
                  progress
                </mark>
              </h1>
              <p className="max-sm:typography-informative-subheading-compact typography-informative-subheading-compact mt-ds-lg max-sm:mx-auto  max-sm:text-center">
                Your game. Your coach. Your journey.
              </p>

              <div className="mt-14 flex items-center gap-10 max-sm:mt-8 max-sm:justify-center">
                {getIconWithText('Remote', VideoCamera)}
                {getIconWithText('On-Court', CourtFlat)}
              </div>

              <div className="mt-14 flex items-center gap-4 max-sm:mt-8 max-sm:justify-center">
                <Button
                  onClick={onGettingStarted}
                  variant="brand"
                  className="w-fit shadow-[0px_4px_20px_0px_rgba(255,92,51,0.50)]"
                  size="lg"
                >
                  Join Bounce for Free
                </Button>
              </div>
              <div className="mt-14 flex gap-4 max-sm:mt-8 max-sm:justify-center">
                {learnMoreJsx}
              </div>
            </div>
          </div>

          <AnimatedCarousel />
        </div>
      </section>
    </>
  );
};

export default HeroSection;

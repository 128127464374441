const ProgressLine = () => {
  return (
    <div className="relative shrink-0">
      <div className="sticky top-[7rem] z-[2] h-8 w-8 rounded-full  bg-color-brand-primary  shadow-[0px_4px_40px_rgba(255,92,51,0.40)] max-md:h-6 max-md:w-6" />
      <div className="absolute bottom-0 left-1/2 h-full w-2 -translate-x-1/2 bg-color-bg-lightmode-primary shadow-[4px_0px_20px_rgba(0,0,0,0.10)] dark:bg-color-bg-darkmode-primary" />
    </div>
  );
};

export default ProgressLine;

import { AnimatedAvatar } from '../styles';

const AnimatedRightAvatars = () => {
  return (
    <div className="absolute right-0 top-1/2 h-[14.75rem] w-[17rem] -translate-y-1/2 max-lg:hidden">
      <div className="relative h-full">
        <AnimatedAvatar
          alt="avatar-2"
          src="/images/players/serious-players-avatars/right-2.png"
          delay="0s"
          className="absolute left-0 top-1/2 h-16 w-16 -translate-y-1/2"
        />
        <AnimatedAvatar
          alt="avatar-1"
          src="/images/players/serious-players-avatars/right-1.png"
          delay="1s"
          className="absolute left-1/2 h-16 w-16 -translate-x-1/2"
        />

        <AnimatedAvatar
          alt="avatar-3"
          src="/images/players/serious-players-avatars/right-3.png"
          delay="2s"
          className="absolute right-0 top-1/2 h-20 w-20 -translate-x-1/2 -translate-y-1/2"
        />
        <AnimatedAvatar
          alt="avatar-4"
          src="/images/players/serious-players-avatars/right-4.png"
          delay="3s"
          className="absolute bottom-0 left-1/2 h-24 w-24 -translate-x-1/2"
        />
      </div>
    </div>
  );
};

export default AnimatedRightAvatars;
